export const User = {
    Auth: '/user/auth',
    GoogleAuth: '/user/googleauth',
    FacebookAuth: '/user/facebookauth',
    RefreshToken: '/user/refresh',
    Register: '/user/registerclient',
    ForgotPasswordSendCode: '/user/forgotpwdsendcode',
    ForgotPasswordConfirmCode: '/user/forgotpwdconfirm',
    ResetPassword: '/user/resetpassword',
    RevokeAccess: '/user/revoke',
    ConfirmEmail: '/user/confirmemail',
    ResendEmailConfirmationCode: '/user/resendconfirm',
    UpdateUser: '/user/update',
    DecryptConfirmationToken: '/user/decryptconfirmationtoken',

};


export const Order = {
    getOrdersCount: '/order/getorderscount',
    changeStatus: '/order/changestatus',
    finishCounting: '/order/finishcounting'
}

export const Depot = {
    getDepotName: '/depot/getdepotname',
}



