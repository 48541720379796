var moment = require('moment-timezone');

export const localDate = (date = null, time = 'HH:mm:ss', timezone = 'America/Edmonton') => {
    var m = date ? moment(date) : moment()
    return m.tz(timezone).format('YYYY-MM-DD ' + time);
}

export const localDateToUTC = (date = null, time = '00:00:00', timezone = 'America/Edmonton') => {
    return moment.utc(new Date(localDate(date, time, timezone)).toUTCString()).format('YYYY-MM-DD HH:mm:ss');
}

export const localDateFromUTC = (utcDate = null, time = '00:00:00', timezone = 'America/Edmonton') => {
    var m = utcDate ? moment.utc(utcDate) : moment.utc();
    return m.tz(timezone).format("YYYY-MM-DD" + (time ? " " + time : ""));
}