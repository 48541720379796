
import { Depot as Route } from '../constants/ApiRoutes';
import { call } from "./config/axiosCalls";

const getDepotName = async (dispatch, callback) => await call({
    dispatch: dispatch,
    callback: callback,
    route: Route.getDepotName,
    method: 'get',
    showErrorMessage: false,
    showSuccessMessage: false,
});

const services = {
    getDepotName: getDepotName,
};

export default services;