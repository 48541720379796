import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Authorization
import Permissions, { PermissionActions } from "../../permissions/Permissions";

//Redux
import { actionsAlert } from '../../redux/index.js';

//Services
import orderService from "../../services/orderService";
import depotService from "../../services/depotService";
import { actionsAuth } from "../../redux";


import { localDate, localDateFromUTC } from '../../helpers/dateTimezone';

// components
import Container from "../../components/Grid/GridContainer.js";
import Section from "../../components/Section/Section";
import Item from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardText from "../../components/Card/CardText.js";
import CardBody from "../../components/Card/CardBody.js";
import Button from "../../components/CustomButtons/Button";
import Loader from "../../components/Loader/InlineLoader";
import VirtualizedTable from "../../components/ReactTable/VirtualizedTable.js";
import Paper from '@material-ui/core/Paper';
import CustomInput from "../../components/CustomInput/CustomInput.js";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';


let isMounted = true;

export default function EmployeeInf() {

    const dispatch = useDispatch();
    const state = useSelector(state => state.auth);
    const history = useHistory();
    const [filter, setFilter] = useState("");

    const handleLogout = useCallback(() => {
        dispatch(actionsAuth.authLogout());
        history.push('/auth/login');
    }, [dispatch, history]);

    const handleLogout2 = useCallback(() => {
        handleLogout();
        return dispatch(actionsAlert.alert({
            Message: "You don't have permission to login this app!",
            Type: 'warning',
        }));
    }, [dispatch, handleLogout]);

    useEffect(() => {
        if (!PermissionActions.userHasAnyPermissions(state.Permissions, [Permissions.DepotEmployee]) && state.Permissions.length > 0)
            handleLogout2();
    }, [handleLogout2, state]);

    useEffect(() => { isMounted = true; return () => { isMounted = false }; }, []);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [depotName, setDepotName] = useState('');



    const getData = useCallback(async (showLoader = true) => {
        await orderService.getOrdersCount(dispatch, {showLoader: showLoader}, async (data) => {
            if (isMounted) {
                for (const prop in data) {
                    if (data[prop] === null) data[prop] = '';
                }
                setLoading(false);
                setRawData(data);
                setFilteredData(data)
            }
        }, (err) => { if (isMounted) { setError(true); setLoading(false); console.log(err) } });

        await depotService.getDepotName(dispatch, async (data) => {
            if (isMounted) {
                setLoading(false);
                setDepotName(data);
            }
        }, (err) => { if (isMounted) { setError(true); setLoading(false); console.log(err) } })
    }, [dispatch]);

    const refreshPage = useCallback(() => {
        setError(false); getData();
    }, [getData]);



    const refreshOrders = useCallback(async () => {
        await orderService.getOrdersCount(dispatch, {showLoader: false}, async (data) => {
            if (isMounted) {
                for (const prop in data) {
                    if (data[prop] === null) data[prop] = '';
                }
                setLoading(false);
                setRawData(data);
                setFilteredData(data)
            }
        }, (err) => { if (isMounted) { setError(true); setLoading(false); console.log(err) } });
    }, [])

    useEffect(()=>{

        var intRef = setInterval(async () => {
            await refreshOrders();
        }, 2000);

        return () => {
            clearInterval(intRef);
        }
    }, [])

    const handleRedirectCountScreen = useCallback(async (currentorder) => {
        const orderData = {
            orderId: currentorder.orderId,
            status: 3 //Order status: Counting
        };
        await orderService.changeStatus(dispatch, orderData, () => {
            history.push({
                pathname: '/countscreen/countscreen',
                search: `?customerId=${currentorder.customerId}&firstName=${currentorder.firstName}&lastName=${currentorder.lastName}&userId=${currentorder?.userId}&orderId=${currentorder.orderId}&numBags=${currentorder.numBags}&driverNote=${encodeURIComponent(currentorder.driverNote)}`,
                state: { detail: 'some_value' }
            });
        });
    }, [dispatch, history]);

    const columns = useMemo(() => [
        {
            width: 200,
            label: 'Customer ID',
            dataKey: 'customerId',
        },
        // {
        //     Header: 'First Name',
        //     accessor: 'firstName',
        //     filterable: true,
        // },
        // {
        //     Header: 'Last Name',
        //     accessor: 'lastName',
        //     filterable: true,
        // },
        {
            width: 200,
            label: 'Order ID',
            dataKey: 'orderId',
        },
        {
            width: 200,
            label: 'Number of Bags',
            dataKey: 'numBags',
        },
        {
            width: 200,
            label: 'Order Date',
            dataKey: 'startDate',
        },
        // {
        //     Header: 'Status',
        //     accessor: 'status',
        // },
        {
            width: 200,
            label: 'Action',
            dataKey: 'Actions'
        },
    ], []);

    const tableData = useMemo(() => {
        let temp = [];
        if (filteredData && filteredData.length) {
            for (let i = 0; i < filteredData.length; i++) {
                let currentItem = i;
                temp.push({
                    numBags: filteredData[i].numBags ? filteredData[i].numBags : '',
                    orderId: filteredData[i].orderId ? filteredData[i].orderId : '',
                    customerId: filteredData[i].customerId ? filteredData[i].customerId : '',
                    firstName: filteredData[i].firstName ? filteredData[i].firstName : '',
                    lastName: filteredData[i].lastName ? filteredData[i].lastName : '',
                    startDate: filteredData[i].startDate ? localDateFromUTC(filteredData[i].startDate, null) : '',
                    status: filteredData[i].status ? filteredData[i].status === 1 ? 'Picked Up' : 'Drop Off' : 'Drop Off',
                    Actions:
                        <Section dFlex justifyContentEnd flexWrap>
                            <Button color="primary" size="sm" currentorder onClick={() => { handleRedirectCountScreen(filteredData[currentItem]) }}>Start Counting</Button>
                        </Section>
                });
            }
        }
        return temp;
    }, [filteredData, handleRedirectCountScreen]);

    useEffect(() => { getData(); }, [getData]);

    const onChangeFilter = useCallback((e) => {
        let value = e.target.value;
        setFilter(value);
        if (value && value.length > 0) {
            setFilteredData(rawData.filter(item => (
                item.customerId.toLowerCase().includes(value.toLowerCase()) ||
                localDateFromUTC(item.startDate, null).toLowerCase().includes(value.toLowerCase()) ||
                item.orderId.toLowerCase().includes(value.toLowerCase())
            )));
        } else {
            setFilteredData(rawData);
        }
    }, [rawData]);

    return (
        <Container  >
            {/*Todo add single source for styles*/}
            <Item xs={12} sm={12} md={12} lg={12}>
                <Card animate fade>
                    <CardHeader color="primary" stats icon>
                        <CardText color="primary"><h5>{depotName}'s Orders</h5></CardText>
                    </CardHeader>
                    <CardBody>

                        <Item lg={3}>
                        <Button onClick={handleLogout} color="primary">Logout</Button>

                        </Item>
                        <Item style={{ marginLeft: '19%' }} lg={7}>
                            <p>Search by Customer ID, order ID, and order date(yyyy-mm-dd)</p>
                            <CustomInput fullWidth labelText="Filter Table" value={filter} onChange={onChangeFilter} type="text"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                } />
                        </Item>



                        {
                            error ?
                                <Section dFlex justifyContentCenter flexColumn>
                                    <h3>Error</h3>
                                    <p>Could not load data</p>
                                    <div><Button color="primary" size="sm" onClick={refreshPage}>Refresh Page</Button></div>
                                </Section>
                                :
                                loading ?
                                    <Loader />
                                    :
                                    (filteredData && filteredData.length) ?
                                        <div style={{ justifyContent: 'center', display: 'flex', marginTop: 70 }}>
                                            <Paper style={{ height: 600, width: '60%' }}>
                                                <VirtualizedTable
                                                    rowCount={filteredData.length}
                                                    rowGetter={({ index }) => tableData[index]}
                                                    columns={columns}
                                                    rowHeight={100}
                                                    headerHeight={80}
                                                />
                                            </Paper>
                                        </div>

                                        :
                                        <div style={{justifyContent: 'center', textAlign: 'center', minHeight: 300}}>
                                            <h3 style={{marginTop: 100}}>No Orders</h3>
                                            <p>There are no orders assigned to your depot at this moment</p>
                                        </div>
                        }
                    </CardBody>
                </Card>
            </Item>
        </Container>
    )
}


