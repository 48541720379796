import { Order as OrderRoute } from '../constants/ApiRoutes';
import { call } from "./config/axiosCalls";


const getOrdersCount = async (dispatch, options = {showLoader: true}, callback) => await call({
    dispatch,
    callback,
    route: OrderRoute.getOrdersCount,
    method: 'get',
    errorMessage: 'We were unable to get the orders of your depot',
    showErrorMessage: true,
    showSuccessMessage: false,
    showLoader: options?.showLoader ? options?.showLoader : false,
});

const changeStatus = async (dispatch, data, callback) => await call({
    dispatch,
    callback,
    data,
    route: OrderRoute.changeStatus,
    method: 'post',
    errorMessage: 'We were unable to update the status of this order',
    showErrorMessage: true,
    showSuccessMessage: false,
});




const finishCounting = async (dispatch, data, callback) => await call({
    
    dispatch,
    callback,
    data,
    route: OrderRoute.finishCounting,
    method: 'post',
    errorMessage: 'We were unable to finish this order',
    showErrorMessage: true,
    showSuccessMessage: true,  
    successMessage: 'Counting was finished successfully!',
    
    
});



const orderService = {
    getOrdersCount: getOrdersCount,
    changeStatus: changeStatus,
    finishCounting: finishCounting,
   
    
};

export default orderService;