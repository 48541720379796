import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import logo from "../../assets/img/logo_depot.png"
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { actionsAlert } from '../../redux/index.js';

// components
import Container from "../../components/Grid/GridContainer.js";
import Item from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";

import CardBody from "../../components/Card/CardBody.js";
import Button from "../../components/CustomButtons/Button";

import TextField from "@material-ui/core/TextField"

import * as math from 'mathjs';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import orderService from '../../services/orderService';

import can_icon from '../../assets/img/can-icon.png';
import milkjug_icon from  '../../assets/img/milk-jug-icon.png';

// Websocket
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebsocketUrl } from 'constants/ApiSettings.js';

export default function CountScreen() {
  const [lastGroup, setLastGroup] = useState('single');
  const state = useSelector(state => state.auth);

  const [fInput, setfInput] = useState('');
  const location = useLocation();
  const [QtyOneLAndUnder, setQtyOneLAndUnder] = useState(0);
  const [QtyOverOneLt, setQtyoverOneLt] = useState(0);
  const [customer, setCustomer] = useState({
    firstName: '',
    lastName: '',
    customerId: '',
    orderId: '',
    userId: null,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const sizeOneLandUnder = "1L and under"
  const sizeOverOneL = "Over 1L"
  const [amountsizeOneLandUnder, setamountsizeOneLandUnder] = useState(0);
  const [amountsizeOverOneL, setamountsizeOverOneL] = useState(0);
  const [item, setItem] = useState(0);
  const [checked, setChecked] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [sizeOneLandUnderPrice] = useState(0.1);
  const [sizeOverOneLrPrice] = useState(0.25);
  const [total, settotal] = useState(0);
  const columns = useMemo(() => [
    {
      field: 'LineItem',
      width: 150,
      headerName: 'Line Item',
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              fontSize: 22.5,
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      }
    },
    {
      field: 'Qty',
      width: 100,
      headerName: 'Qty',

      renderCell: (cellValues) => {
        return (
          <div
            style={{
              fontSize: 25,
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      }
    },
    {
      field: 'value',
      headerName: 'Amount',
      width: 150,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              fontSize: 25,
              width: "100%",
            }}
          >
            ${Number(cellValues.value).toFixed(2)}
          </div>
        );
      }
    },
  ], []);



  const [wsConn, setWsConn] = useState(null);

  // Websocket connection
  const getSocketUrl = useCallback(() => {
    var userId = state?.User?.Id ? state?.User?.Id : 'employee';

    console.log(userId)
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(`${WebsocketUrl}?t=${userId}&d=employee`);
      }, 2000);
    });
  }, [state]);
  const {   
    sendJsonMessage: wsSend,
    lastJsonMessage: wsLast,
    readyState: wsState,
    getWebSocket: wsMessage, 
  } = useWebSocket(
    getSocketUrl,
    {
      onOpen: () => console.log('opened', state.User.Id),
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => true,
      share: true,
    }
  );

  // Websocket connection status
  const wsConnStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  };

  const wsHook = {wsConnStatus, wsSend, wsLast, wsState, wsMessage }










  const CustomNoRowsOverlay = () => {

    return (
      <GridOverlay>
        <Container justify="center">
          <Item xs={3} lg={4}>
            <h3>No items </h3>
          </Item>
        </Container>
      </GridOverlay>
    );
  }

  const removeItemObject = (ar, value) => {
    for (var i = 0; i < ar.length; i++) {
      if (ar[i].id === value) {
        ar.splice(i, 1);
      }
    }
  }

  const handleredirectEmployeeScreen = async () => {
    const lineItems = [];
    data.forEach(d => {
      var lineItem = {
        lineItem: d.LineItem,
        qty: d.Qty,
        value: d.value,
      };
      lineItems.push(lineItem);
    });

    const orderData = {
      id: customer.orderId,
      bottleBig: QtyOverOneLt,
      bottleSmall: QtyOneLAndUnder,
      totalAmount: total,
      lineItems: lineItems,
    };

    dispatch(actionsAlert.alert({
      Message: 'Are you sure you want to finish the count of this order?',
      Type: 'info',
      Confirm: true,
      ConfirmBtnText: 'Yes',
      CancelBtnText: 'No',
      ConfirmCallback: async () => {

        await orderService.finishCounting(dispatch, orderData, (ret) => {
                 
          // Websocket -> trigger the client to reload the wallet
          if (wsHook?.wsState == 1) { // if the connection is open
            if (customer?.userId) {
              console.log('SENDING....')
              wsHook.wsSend({
                "UserId": customer?.userId,
                // "DeviceToken": "", --> if used, it will restrict this action only to a specific device from this user
                "Action": 'load_wallet,load_history',
              });
            }
          }

          history.push('/employee/employeeInf');  
        });
      }
    }));

  };

  const handleCancel = useCallback(async () => {
    const data = {
      orderId: customer.orderId,
      status: 1, //OrderStatus: Pickedup
    }
    await orderService.changeStatus(dispatch, data, () => {
      history.push('/employee/employeeInf');
    })
  }, [dispatch, customer, history]);

  const handleClickButton = (value, group = 'single') => (e) => {

    if (value == '*' && (fInput == '' || fInput.substr(-1) == '*')) {
      return false;
    }

    let multiply = '';
    if ((lastGroup != group || group == 'auto_multiply')) {
      setLastGroup(group)
      if (fInput != '' && fInput.substr(-1) != '*' && value != '*') {
        multiply = '*'
      }
    }

    setfInput(fInput + multiply + value)
  }

  const handleUpdateTotal = useCallback((updatedData) => {

    /* Todo refactor*/
    var lineitem = updatedData.filter(d => d.LineItem === sizeOneLandUnder);
    const results = lineitem.reduce((total, currentValue) => total = Number(total) + Number(currentValue.Qty.toString().includes('*') ? (currentValue.Qty.toString().split('*')[0] * currentValue.Qty.toString().split('*')[1]) : currentValue.Qty), 0);
    setQtyOneLAndUnder(results)
    const totalamount = lineitem.reduce((total, currentValue) => total = total + currentValue.value, 0);
    setamountsizeOneLandUnder(totalamount)


    var lineitems = updatedData.filter(d => d.LineItem === sizeOverOneL);

    const result = lineitems.reduce((total, currentValue) => total = Number(total) + Number(currentValue.Qty.toString().includes('*') ? (currentValue.Qty.toString().split('*')[0] * currentValue.Qty.toString().split('*')[1]) : currentValue.Qty), 0);
    setQtyoverOneLt(result)
    const totalamountOverOneL = lineitems.reduce((total, currentValue) => total = total + currentValue.value, 0);

    setamountsizeOverOneL(totalamountOverOneL)
    let total = totalamountOverOneL + totalamount;
    settotal(total)

  }, []);

  const handleDeleteRow = useCallback((value) => {
    setChecked(false)

    let items = [...data];
    let selectedvalue = 0;

    for (let i = 0; i <= selectionModel.length - 1; i++) {
      if (selectionModel[i].id) {
        selectedvalue = Number(selectionModel[i].id);
      } else {
        selectedvalue = Number(selectionModel[i]);
      }
      removeItemObject(items, selectedvalue);
    }

    setData(items);
    setItem(item)
    handleUpdateTotal(items)
  }, [selectionModel, handleUpdateTotal, data, item])


  const addToTable = useCallback((under) => {
    let incrementItem
    incrementItem = item + 1;
    setfInput(math.evaluate(fInput))
    /* Todo refactor*/
    setItem(incrementItem)
    if (under) {
      let pi = [...data, {
        id: incrementItem,
        LineItem: sizeOneLandUnder,
        Qty: (fInput ? fInput.includes('*') ? (fInput.split('*')[0] * fInput.split('*')[1]) : fInput : 1),
        value: sizeOneLandUnderPrice * (fInput ? fInput.includes('*') ? (fInput.split('*')[0] * fInput.split('*')[1]) : fInput : 1),
      }];
      setData(prevItems => [...prevItems, {
        id: incrementItem,
        LineItem: sizeOneLandUnder,
        Qty: fInput ? fInput : 1 + "",
        value: sizeOneLandUnderPrice * (fInput ? fInput.includes('*') ? (fInput.split('*')[0] * fInput.split('*')[1]) : fInput : 1),
        itemselected: checked,
      }]);

      handleUpdateTotal(pi)
    } else {
      let pi = [...data, {
        id: incrementItem,
        LineItem: sizeOverOneL,
        Qty: (fInput ? fInput.includes('*') ? (fInput.split('*')[0] * fInput.split('*')[1]) : fInput : 1),
        value: sizeOverOneLrPrice * (fInput ? fInput.includes('*') ? (fInput.split('*')[0] * fInput.split('*')[1]) : fInput : 1),

      }];
      setData(prevItems => [...prevItems, {
        id: incrementItem,
        LineItem: sizeOverOneL,
        Qty: fInput ? fInput : 1 + "",
        value: sizeOverOneLrPrice * (fInput ? fInput.includes('*') ? (fInput.split('*')[0] * fInput.split('*')[1]) : fInput : 1),

      }]);
      handleUpdateTotal(pi)
    }

    setfInput("")
  }, [checked, data, fInput, handleUpdateTotal, item, sizeOneLandUnderPrice, sizeOverOneLrPrice]);


  const handleClearInput = (e) => {
    setfInput("")
    setLastGroup('single')
  }

  const parseQuerystring = (targetQueryString) => {
    let qs = targetQueryString;

    let qsArray = [];
    if (qs.length === 0) {

      return null;
    }

    if (qs.indexOf("?") !== -1) {

      qs = qs.slice(1, qs.length);
    }
    qsArray = qs.split("&");
    let parsedQs = qsArray.map((s) => {
      let item = s.split("=");
      return { param: item[0], value: item[1] };
    });

    return parsedQs;
  };



  const parseUrl = useCallback(() => {
    const parsed = parseQuerystring(location.search);

    let firstNameParam = parsed.find(p => p.param === 'firstName');
    let lastNameParam = parsed.find(p => p.param === 'lastName');
    let customerIdParam = parsed.find(p => p.param === 'customerId');
    let orderIdParam = parsed.find(p => p.param === 'orderId');
    let numBagsParam = parsed.find(p => p.param === 'numBags');
    let orderUserId = parsed.find(p => p.param === 'userId');
    let driverNoteParam = parsed.find(p => p.param === 'driverNote');

    const customer = {
      firstName: firstNameParam ? firstNameParam.value : '',
      lastName: lastNameParam ? lastNameParam.value : '',
      customerId: customerIdParam ? customerIdParam.value : '',
      orderId: orderIdParam ? orderIdParam.value : '',
      userId: orderUserId ? orderUserId.value : null,
      numBags: numBagsParam ? numBagsParam.value : 0,
      driverNote: driverNoteParam ? (driverNoteParam.value == '' || driverNoteParam.value == null || driverNoteParam.value == 'null' ? ' - ' : driverNoteParam.value) : '-',
    }

    setCustomer(customer);
  }, [location]);

  useEffect(() => { parseUrl(); }, [parseUrl]);

  return (
    <Container direction="row">
      <Card animate fade>
        <CardBody>
          <Container direction="row">
            {
              <Container justify="left">
                <Item xs={5}>
                  <Card style={{ "height": "625px", "width": "500px", marginLeft: "20px", "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', padding: '10px 10px 10px 10px', fontSize: '15px' }}>
                    <Container direction="row">
                      <Item xs={12}>
                        <h4 style={{margin: 0}}>{`Customer Name: ${customer.firstName} ${customer.lastName}`}</h4>
                      </Item>
                      <Item xs={12}>
                        <h4 style={{margin: 0}}>{`Customer ID: ${(decodeURIComponent(customer.customerId)).replace(/\ /g, '')}`}</h4>
                      </Item>
                      <Item xs={4} lg={3}>
                        <h4 style={{margin: 0}}>{`# Bags: ${customer.numBags}`}</h4>
                      </Item>
                      <Item xs={12}>
                        <h4 style={{margin: 0}}>{`Driver Note:`} <small style={{fontSize: '90%'}}>{decodeURIComponent(customer.driverNote)}</small></h4>
                      </Item>
                    </Container>

                    <DataGrid
                      item xs={12} sm={6} md={3} xl={6}
                      columns={columns}
                      rows={(data ?? []).sort((a, b) => b?.id - a?.id)}
                      hideFooterPagination
                      disableMultipleSelection={true}
                      checkboxSelection
                      headerStyle={{ fontSize: '20px' }}
                      components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                      }}
                      onSelectionModelChange={(newSelection) => {
                        setSelectionModel(newSelection.selectionModel);
                      }}

                    />

                  </Card>
                  <Item xs={12} lg={12}>
                    <Container direction="row">
                      <Item xs={7}>
                        <h3 style={{margin:0}}>{`1L and Under = ${QtyOneLAndUnder}`}</h3>
                        <h3 style={{margin:0}}>{`Over 1L = ${QtyOverOneLt}`}</h3>
                      </Item>
                      <Item xs={5} lg={4}>
                        <h3 style={{margin:0}}>{`$ ${Number(amountsizeOneLandUnder).toFixed(2)}`}</h3>
                        <h3 style={{margin:0}}>{`$ ${Number(amountsizeOverOneL).toFixed(2)}`}</h3>
                      </Item>
                      <Item xs={12}>
                        <h2 style={{margin:0}}>{`Total Amount = $ ${Number(total).toFixed(2)}`}</h2>
                      </Item>
                    </Container>
                    <Container></Container>
                  </Item>
                </Item>

                <Item lg={4} style={{ marginLeft: '-3%' }}>
                  <Card style={{ "width": "500px", "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'boxShadow': '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <Container alignItems="center" direction="column">

                      <Item xs={12} lg={12}>
                        <TextField inputProps={{ style: { fontSize: "3rem" } }} type="text" value={fInput} fullWidth />
                      </Item>

                      <Item xs={12} lg={12}>
                        <ButtonGroup variant="text" color="primary" aria-label="large outlined primary button group">
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(12, 'auto_multiply')}>12</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(15, 'auto_multiply')}>15</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(18, 'auto_multiply')}>18</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(24, 'auto_multiply')}>24</Button>
                        </ButtonGroup>
                      </Item>

                      <Item xs={12} lg={8}>
                        <ButtonGroup style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }} variant="text" aria-label="large outlined primary button group">
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(7)}>7</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(8)}>8</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(9)}>9</Button>
                        </ButtonGroup>
                        <ButtonGroup style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }} variant="text" aria-label="large outlined primary button group">
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(4)}>4</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(5)}>5</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(6)}>6</Button>
                        </ButtonGroup>
                        <ButtonGroup style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }} variant="text" aria-label="large outlined primary button group">
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(1)}>1</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(2)}>2</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(3)}>3</Button>
                        </ButtonGroup>
                        <ButtonGroup style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }} variant="text" aria-label="large outlined primary button group">
                          <Button style={{ fontSize: '40px', maxWidth: '255px', maxHeight: '80px', minWidth: '145px', minHeight: '80px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton(0)}>0</Button>
                          <Button style={{ fontSize: '40px', maxWidth: '255px', maxHeight: '80px', minWidth: '30px', minHeight: '80px', margin: '10px 10px 10px 10px' }} onClick={handleClickButton("*")}>X</Button>
                        </ButtonGroup>
                        <ButtonGroup style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }} variant="text" color="primary" aria-label="large outlined primary button group">
                          <Button color="primary" style={{ fontSize: '20px', maxWidth: '200px', maxHeight: '80px', minWidth: '100px', minHeight: '80px', margin: '10px 10px 10px 10px' }} onClick={handleDeleteRow}>Delete Row</Button>
                          <Button style={{ fontSize: '20px', maxWidth: '200px', maxHeight: '80px', minWidth: '100px', minHeight: '80px', margin: '10px 10px 10px 10px' }} onClick={handleClearInput}>Clear</Button>
                        </ButtonGroup>

                      </Item>

                    </Container>
                  </Card>
                  <Button color="transparent" onClick={handleCancel} style={{ fontSize: '20px', maxWidth: '200px', maxHeight: '80px', minWidth: '100px', minHeight: '80px', margin: '10px 100px 10px 10px' }}>Cancel</Button>
                  <Button color="primary" onClick={handleredirectEmployeeScreen} style={{ fontSize: '20px', maxWidth: '200px', maxHeight: '80px', minWidth: '100px', minHeight: '80px', margin: '10px 10px 10px 10px' }}>Finish</Button>
                </Item>

                <Item lg={2} style={{ marginLeft: '-8%' }}>
                  <br />                  
                  <Button color="primary" style={{ borderRadius: 10, fontSize: '25px', maxWidth: '800px', maxHeight: '320px', minWidth: '280px', minHeight: '365px', margin: '10px 10px 10px 160px', fontWeight: 'bold' }} onClick={() => addToTable(false)}>
                    <div>
                      <div style={{marginTop: 40, opacity: 0.7, marginBottom: 30}}><img src={milkjug_icon} width={72} height={72} /></div>
                      <div>Over 1L</div>
                    </div></Button>
                  <br />
                  <Button color="primary" style={{ borderRadius: 10, fontSize: '25px', maxWidth: '800px', maxHeight: '320px', minWidth: '280px', minHeight: '365px', margin: '10px 10px 10px 160px', fontWeight: 'bold' }} onClick={() => addToTable(true)}>
                    <div>
                      <div style={{marginTop: 40, opacity: 0.7, marginBottom: 30}}><img src={can_icon} width={72} height={72} /></div>
                      <div>1L  and under</div>
                    </div></Button>
                </Item>
              </Container>}




          </Container>
        </CardBody>
      </Card>


    </Container>
  )
}


